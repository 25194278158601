<template>
  <div>
    <header-title title="新增配置"></header-title>
    <rs-row>
     <rs-col :span="8">
       <rs-form ref="form" :model="formData" :rules="rules" label-width="120px">
          <rs-form-item label="当前项目" prop="mallCode">
            <rs-select :popper-append-to-body="false" v-model="formData.mallSlect" popper-class="dialog-popper" value-key="mallCode" placeholder="请选择"  @change="changeMallCode">
              <rs-option v-for="item in mallList" :key="item.mallCode" :label="item.mallName" :value="item">
              </rs-option>
            </rs-select>
        </rs-form-item>
       </rs-form>
     </rs-col>
     <rs-col>
        <renderTable :list="tableData" :columns="tableColumns" border :dispalyColumn="false">
          <rs-table-column prop="numberDigit" slot="numberDigit" label="小数保留位数" width="220" fixed="right" align="left">
              <template slot-scope="scope">
                <rs-select
                  v-model="scope.row.numberDigit"
                  placeholder="请选择小数保留位数"
                >
                <rs-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </rs-option>
                 </rs-select>
              </template>
            </rs-table-column>
            <rs-table-column slot="mantissaModeName" label="尾数计算方式" width="220" fixed="right" align="left">
              <template slot-scope="scope">
                 <rs-select v-model="scope.row.mantissaMode" placeholder="请选择尾数计算方式" @change="handleChange(scope.row,scope.$index)">
                    <rs-option
                      v-for="item in mantissaModeNameOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                </rs-option>
                </rs-select>
              </template>
            </rs-table-column>
        </renderTable>
     </rs-col>
    </rs-row>
    <div class="edit-form button-box" style="text-align: right">
        <rs-button size="small"  @click="back">取消</rs-button>
        <rs-button size="small" @click="save" type="primary" :loading="saveLoading">保存</rs-button>
    </div>
  </div>
</template>
<script>
import Utils from '@/utils/utils'
import Api from '@/api/reportServer'
import Api1 from '@/api/api'
import headerTitle from '@/components/header'
export default {
  name: "addReportCenterConfig",
  components:{headerTitle},
  data(){
    return {
      formData:{},
      mallList:[],
      saveLoading:false,
      options:[
        {
          value: 2,
          label: '两位小数'
        },
        {
          value: 4,
          label: '四位小数'
        },
         {
          value:6,
          label: '六位小数'
        },
        {
          value: 8,
          label: '八位小数'
        }
      ],
      //尾数计算方式 
      mantissaModeNameOptions:[
        {
          value: 1,
          label: '四舍五入、尾差求和'
        },
        {
          value: 2,
          label: '尾数不进位、尾差求和'
        }
      ],
      fieldNameArr:[{
        fieldName:'面积',
        fieldType:'area'
      },
      {
        fieldName:'规格',
        fieldType:'spec'
      },
      {
        fieldName:'金额',
        fieldType:'amount'
      },
      {
        fieldName:'扣率',
        fieldType:'discount'
      }], //字段名称
      tableData:[],
      tableColumns: [
        {
          prop: 'fieldName',
          label: '字段名称'
        },
        {
          prop: 'fieldLengthName',
          label: '字段长度'
        },
        {
          slot: 'numberDigit',
          label: '小数保留位数'
        },
        {
          slot: 'mantissaModeName',
          label: '尾数计算方式'
        }
      ],
      rules:{
        mallCode:[
           { required: true, message: '请选择项目', trigger: 'blur' },
        ]
      },
      mallInfo:{}
    }
  },
  created(){
    this.initTableData();
    this.getMall();
  },
  methods:{
    async getMall(){
      let user = Utils.getSession("userInfo");
      let data = await Api.getMall({'userId':user.userId})
      this.mallList = data.data.data;
    },
    changeMallCode(val){
      console.log('val:',val)
      this.mallInfo.mallId  = val.id
      this.mallInfo.companyId  = val.companyId
      this.mallInfo.mallName  = val.mallName
      this.mallInfo.mallCode = val.mallCode
    },
    //初始化表格
    initTableData(){
      this.tableData = []
      this.fieldNameArr.forEach(item=>{
        this.tableData.push({
          fieldName:item.fieldName,
          fieldType:item.fieldType,
          fieldLength:-1,
          fieldLengthName:'最大长度',
          numberDigit:4,
          mantissaMode:1,
          mantissaModeName:'四舍五入、尾差求和'
        })
      })      
    },
    back(){
      this.$router.back();
    },
    //手动新增尾数计算方式
    handleChange(row,index){
      let seleItem = this.mantissaModeNameOptions.find(item=>{
        if(item.value == row.mantissaMode){
          return item
        }
      })
      this.tableData[index].mantissaModeName = seleItem.label
    },
    save(){
      if(!this.mallInfo.mallId){
        this.$message.error('请选择项目');
        return;
      }
      let params = this.tableData.map(item=>{
        item.mallId = this.mallInfo.mallId
        item.companyId = this.mallInfo.companyId
        item.mallName = this.mallInfo.mallName
        item.mallCode = this.mallInfo.mallCode
        return item
      })
      Api.reportConfigSave(params).then(res=>{
        if(res.data.code==200){
          this.$message.success('新增成功')          
          this.$router.push('/reportCenterConfig');
        }else{
          this.$message.error(res.data.message);
        }
      })
      
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .configType{
  .rs-form-item__content{
    margin-left: 40px !important;
  }  
}
</style>