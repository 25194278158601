var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("header-title", { attrs: { title: "新增配置" } }),
      _c(
        "rs-row",
        [
          _c(
            "rs-col",
            { attrs: { span: 8 } },
            [
              _c(
                "rs-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "rs-form-item",
                    { attrs: { label: "当前项目", prop: "mallCode" } },
                    [
                      _c(
                        "rs-select",
                        {
                          attrs: {
                            "popper-append-to-body": false,
                            "popper-class": "dialog-popper",
                            "value-key": "mallCode",
                            placeholder: "请选择",
                          },
                          on: { change: _vm.changeMallCode },
                          model: {
                            value: _vm.formData.mallSlect,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "mallSlect", $$v)
                            },
                            expression: "formData.mallSlect",
                          },
                        },
                        _vm._l(_vm.mallList, function (item) {
                          return _c("rs-option", {
                            key: item.mallCode,
                            attrs: { label: item.mallName, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "rs-col",
            [
              _c(
                "renderTable",
                {
                  attrs: {
                    list: _vm.tableData,
                    columns: _vm.tableColumns,
                    border: "",
                    dispalyColumn: false,
                  },
                },
                [
                  _c("rs-table-column", {
                    attrs: {
                      slot: "numberDigit",
                      prop: "numberDigit",
                      label: "小数保留位数",
                      width: "220",
                      fixed: "right",
                      align: "left",
                    },
                    slot: "numberDigit",
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "rs-select",
                              {
                                attrs: { placeholder: "请选择小数保留位数" },
                                model: {
                                  value: scope.row.numberDigit,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "numberDigit", $$v)
                                  },
                                  expression: "scope.row.numberDigit",
                                },
                              },
                              _vm._l(_vm.options, function (item) {
                                return _c("rs-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("rs-table-column", {
                    attrs: {
                      slot: "mantissaModeName",
                      label: "尾数计算方式",
                      width: "220",
                      fixed: "right",
                      align: "left",
                    },
                    slot: "mantissaModeName",
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "rs-select",
                              {
                                attrs: { placeholder: "请选择尾数计算方式" },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleChange(
                                      scope.row,
                                      scope.$index
                                    )
                                  },
                                },
                                model: {
                                  value: scope.row.mantissaMode,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "mantissaMode", $$v)
                                  },
                                  expression: "scope.row.mantissaMode",
                                },
                              },
                              _vm._l(
                                _vm.mantissaModeNameOptions,
                                function (item) {
                                  return _c("rs-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "edit-form button-box",
          staticStyle: { "text-align": "right" },
        },
        [
          _c(
            "rs-button",
            { attrs: { size: "small" }, on: { click: _vm.back } },
            [_vm._v("取消")]
          ),
          _c(
            "rs-button",
            {
              attrs: {
                size: "small",
                type: "primary",
                loading: _vm.saveLoading,
              },
              on: { click: _vm.save },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }